// change the theme
$enable-negative-margins: true;
@import '~bootstrap/scss/bootstrap-utilities.scss';

@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

$color-mode-type: data;

$theme-colors: (
  "primary": #002e5b,
  "secondary": #fde428,
  "tertiary" : #FFF,
  "success": #198754,
  "danger": #dc3545,
  "warning": #ffc107,
  "info": #0dcaf0,
  "light":#f8f9fa,
  "dark":#262A3C,
  "muted": #bebebe
);

$custom-theme-colors: (
  "gray": #E5E5E5,
  "online": #20FF1C,
  "blue" : #56CCE9,
  "gray-0" : #F6F6F6,
  "gray-100": #dee2e7,
  "gray-200": #8b96a5
);


$theme-colors: map-merge($theme-colors, $custom-theme-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
$utilities-text-colors: map-loop($utilities-colors, rgba-css-var, "$key", "text");
$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, "$key", "bg");

$utilities: map-merge(
  $utilities,
  (
        "color": map-merge(
          map-get($utilities, "color"),
          (
            values: map-merge(
              map-get(map-get($utilities, "color"), "values"),
              (
                $theme-colors
              ),
            ),
          ),
        ),
        "background-color": (
            property: background-color,
            class: bg,
            local-vars: (
              "bg-opacity": 1
            ),
            values: map-merge(
              $utilities-bg-colors,
              (
                "transparent": transparent
              )
            )
        ),
        "border-color": (
            property: border-color,
            class: border,
            local-vars: (
            "border-opacity": 1
            ),
            values: map-merge(
                $utilities-border-colors,
                (
                    $theme-colors
                )
            )
        )
    )
);


$input-focus-border-color:          var(--bs-primary);
$dropdown-link-active-bg:           var(--bs-primary);

$form-select-color:                 var(--bs-body-color);
$form-select-focus-border-color:    var(--bs-primary);
$form-select-indicator-color:       var(--bs-primary);
$form-select-focus-box-shadow:      none;

$accordion-button-focus-box-shadow: none;
$accordion-button-active-bg:        var(--bs-secondary);
$accordion-button-active-color:     var(--bs-primary);

$form-check-input-checked-border-color: var(--bs-primary);
//$input-bg:                          var(--bs-white);

$font-family-sans-serif:            'Poppins', 'Roboto';

$body-bg:                            #F6F6F6;
$body-bg-dark:                       #201E2F;
$body-color:                         #1C1C1C;

$card-bg:                            #FFF;
$card-bg-dark:                       #262A3C;
$card-spacer-x:                      1.25rem;
$cars-spacer-y:                      1.25rem;

$form-check-input-checked-bg-color:  var(--bs-primary);

$input-focus-box-shadow:            none;

$card-border-radius:                0.375rem;
$list-group-bg:                     'transparent';

$breadcrumb-bg:                     $card-bg;
$breadcrumb-padding-x:              '0.75rem';
$breadcrumb-padding-y:              '1rem';
$breadcrumb-border-radius:          $card-border-radius;

$table-bg:                          var(--bs-body-bg) !important;
$table-color:                       inherit !important;
//$table-accent-bg:                   var(--bs-body-bg) !important;
$table-hover-bg:                    var(--bs-body-bg) !important;

$dropdown-bg:                       var(--bs-white);

$list-group-border-width:           1px;
$list-group-border-color:           var(--bs-border-color-translucent);

$pagination-color:                  var(--bs-body-color);
$pagination-active-color:           var(--bs-white);
$pagination-active-bg:              var(--bs-primary);
$pagination-active-border-color:    var(--bs-card-bg);

$pagination-hover-color:            $link-hover-color;
$pagination-hover-bg:               $gray-200;
$pagination-hover-border-color:     $gray-300;


:root {
  --toastify-toast-width: 400px;
  --toastify-font-family: 'Poppins', 'Roboto';
  --toastify-color-light: #FFF;
  --toastify-color-dark: #262A3C;
  --toastify-color-info: var(--bs-info);
  --toastify-color-success: var(--bs-success);
  --toastify-color-warning: var(--bs-warning);
  --toastify-color-error: var(--bs-danger);
  --toastify-text-color-dark: var(--bs-body-color);
  --toastify-text-color-light: var(--bs-body-color);
}

@include color-mode(light) {
    body {
      border-top: 26px solid var(--bs-secondary);
    }

    #logo path {
      fill: var(--bs-primary);
    }

    .user-nav {
        background-color: var(--bs-white);
    }

    .navbar-nav .nav-link.active {
        background-color: var(--bs-primary) !important;
        color: var(--bs-white);
    }

    .rc-slider-handle {
        background-color: var(--bs-white);
        border: 2px solid var(--bs-primary);
    }

    .rc-slider-handle-dragging {
        border-color: var(--bs-primary) !important;
        box-shadow: 0 0 0 5px var(--bs-primary) !important;
    }

    #interviews-table tbody tr:nth-of-type(odd) {
      border-top: 2px solid var(--bs-primary);
    }

    .react-select-container {
        .react-select__menu {
            background-color: $card-bg;
        }
    }

    .react-tel-input ul.country-list {
        background-color: $card-bg !important;
        li.active, .country:hover {
            background-color: $body-bg !important;
        }
    }
}

@include color-mode(dark) {
    :root {
        --bs-card-bg: #{$dark};
        color-scheme: dark;
        $rhap_background-color: #{$dark} !default; // Color of the player background
        $rhap_bar-color: var(--bs-secondary) !default;     // Color of volume and progress bar
    }
    body {
      border-top: 26px solid var(--bs-primary);
    }

    #logo path {
      fill: var(--bs-body-color);
    }

    ol.breadcrumb {
        background-color: $card-bg-dark;
    }


    body.qJTHM {
     background-color:red;
    }

    #login-with-google-container iframe body {
        background-color:red;
    }

    .card-bg {
        background-color: $card-bg-dark;
    }
    .card {
        background-color: var(--bs-dark);
    }
    .dropdown-menu {
        background-color: var(--bs-dark);
    }
    .user-nav {
        background-color: var(--bs-dark);
    }

    .navbar-nav .nav-link.active {
        background-color: var(--bs-body-color) !important;
        color: var(--bs-body-bg);
    }

    .highlight-hover:hover {
        background-color: var(--bs-body-bg) !important;
    }
    .missing-user-image {
        color: var(--bs-dark) !important;
    }
    .btn-primary, .page-link.active, .active > .page-link {
        color: var(--bs-body-bg);
        background-color: var(--bs-body-color);
    }

    #interviews-table tbody tr:nth-of-type(odd) {
      border-top: 2px solid var(--bs-secondary);
    }

    .rc-slider-track {
        background-color: var(--bs-secondary);
    }

    strong {
        color: var(--bs-white);
    }

    .react-input-emoji--container {
        background-color: var(--bs-dark);
        color: var(--bs-body-color) !important;
    }

    .element-active {
        @extend .text-primary;
        @extend .bg-body;
    }

    .rhap_container {
        background-color: $card-bg-dark;
    }

    .page-link {
        background-color: $card-bg-dark;
    }
    .page-link:hover {
        color: var(--bs-light) !important;
        background-color: var(--bs-body-bg) !important;
    }

    .react-tel-input ul.country-list {
        background-color: $card-bg-dark !important;
        li.active, .country:hover {
            background-color: $body-bg-dark !important;
        }
    }

    .react-select-container {
      .react-select__menu {
        background-color: var(--bs-dark);
      }

      .react-select__option {
        background-color: var(--bs-dark);
      }
      .react-select__input {
        color: var(--bs-body-color) !important;
      }
    }

    .rc-slider-handle {
        background-color: var(--bs-dark);
        border: 2px solid var(--bs-secondary);
    }

    .rc-slider-handle-dragging {
        border-color: var(--bs-secondary) !important;
        box-shadow: 0 0 0 5px var(--bs-secondary) !important;
    }

    .btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show, .btn:hover {
        color: inherit;
        background-color: inherit;
        border-color: inherit;
    }

    .btn-link:hover {
        border-color: transparent !important;
    }

    .rct-node-icon {
        color:var(--bs-secondary);
    }

    .btn-outline-primary, .btn-outline-primary:hover {
        color: var(--bs-body-color) !important;
        border-color: var(--bs-body-color) !important;
    }

    .react-select-container {
        .react-select__menu {
            background-color: $card-bg-dark;
        }
    }

    .react-tel-input ul.country-list {
        background-color: $card-bg-dark !important;
        li.active, .country:hover {
            background-color: $body-bg-dark !important;
        }
    }
}

$rhap_theme-color: var(--bs-body-color) !default;   // Color of all buttons and volume/progress indicators
$rhap_time-color: var(--bs-body-color) !default;       // Font color of current time and duration
$rhap_font-family: 'Poppins', 'Roboto' !default;   // Font family of current time and duration
$rhap_bar-color: var(--bs-secondary) !default;     // Color of volume and progress bar

@import 'react-h5-audio-player/src/styles.scss';

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

.rc-slider-rail {
    background-color: var(--bs-gray);
}

.rc-slider-track {
    background-color: var(--bs-primary);
}

.rc-slider-handle {
    left: 0%;
    transform: translateX(-50%);
    width: 24px;
    height: 24px;
    margin-top: -10px;
    opacity: 1;
    box-shadow: none;
    z-index: 0;
}

.react-select-container {
  .react-select__control {
    background-color: var(--bs-body-bg);
    border-color: var(--bs-border-color);
    transition: none;

    &:hover {
      border-color: var(--bs-body-color) !important;
    }
  }
  .react-select__control--is-focused {
    box-shadow: none;
  }

  .react-select__menu {
    background-color: $card-bg;
    border: 1px solid var(--bs-border-color);
    z-index: 100 !important;
  }

  .react-select__option {
    background-color: var(--bs-card-bg);

    &:hover {
      background-color: var(--bs-body-bg);
    }
  }

  .react-select__indicator-separator {
    background-color: var(--bs-border-color);
  }

  .react-select__placeholder,
  .react-select__single-value,
  .react-select__option--is-selected {
    color: var(--bs-body-color);
  }
}


body {
  min-height: 100%;
}

.cursor-pointer {
    cursor:pointer;
}

a, .btn-link, .btn-link:hover {
    color: inherit;
    text-decoration: none;
}

.shadow-hover:hover {
    box-shadow: 0 .5rem 1rem rgba($black, .15);
}

.w-70 {
    width: 70% !important
}

.breadcrumb {
  border-color: var(--bs-card-border-color);
}

.react-datepicker-wrapper {
    width: 1%;
    position: relative;
    min-width: 0;
    flex: 1 1 auto;
}

// .form-select {
//     color: inherit;
// }
//
.form-control:focus,.form-select:focus {
    border-color: inherit;
}

.form-control.calendar {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

td {
    vertical-align:middle;
}

.react-tel-input .form-control:focus {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border-color: var(--bs-primary);
}

.react-tel-input .flag-dropdown {
    background-color: var(--bs-tertiary-bg);
    border: var(--bs-border-width) solid var(--bs-border-color);
}

.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
    background-color: var(--bs-tertiary-bg);
    border: var(--bs-border-width) solid var(--bs-border-color);
}

.react-tel-input .flag-dropdown.open .selected-flag {
    background-color: var(--bs-tertiary-bg);
    border: var(--bs-border-width) solid var(--bs-border-color);
}

.page-link:focus {
    box-shadow:none;
}

///






input:autofill {
    background-color: var(--bs-secondary);
}

input:-webkit-autofill {
    background-color: var(--bs-secondary);
}

.missing-user-image {
    background-color: var(--bs-gray-200);
}

.border-online {
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-online-rgb), 0.25);
}

.border-danger {
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.overlay-image-crop {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.Toastify__toast {
    border: 1px solid var(--bs-border-color);
    h5 {
        font-size: 1.125rem;
    }

    h6 {
        font-size: 1rem;
    }
}

.Toastify__toast-icon {
    width: 30px;
}

.Toastify__close-button {
    margin-top: auto;
    margin-bottom: auto;
    svg {
        height: 20px;
        width: 20px;
        margin-right: 10px;
    }
}

.row-color {
    color: var(--bs-alert-color);
    background-color: var(--bs-alert-bg);
    border-color: var(--bs-alert-border-color);
}

.main-navbar-hover {
    border-top: 4px solid transparent;
}

.main-navbar-hover:hover {
    border-top: 4px solid var(--bs-primary) !important;
}

.rounded-top-image {
    border-top-right-radius: $card-border-radius;
    border-top-left-radius: $card-border-radius;
}

.rounded-left-image {
    border-top-left-radius: $card-border-radius;
    border-bottom-left-radius: $card-border-radius;
}

.mb-25 {
    margin-bottom: 0.75rem;
}

.fs-0 {
    font-size: 0.425em;
    line-height: 0.1em;
}

// important
.react-tel-input .form-control {
    height: inherit !important;
    width: inherit !important;
    background-color: var(--bs-body-bg);
    border: var(--bs-border-width) solid var(--bs-border-color);
}

.react-tel-input .form-control:focus {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border-color: inherit;
}

.react-tel-input .flag-dropdown {
    background-color: var(--bs-tertiary-bg);
}

.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
    background-color: var(--bs-tertiary-bg);
    border: var(--bs-border-width) solid var(--bs-border-color);
}

.react-tel-input .flag-dropdown.open .selected-flag {
    background-color: var(--bs-tertiary-bg);
    border: var(--bs-border-width) solid var(--bs-border-color);
}

.react-tel-input .country-list .country.highlight {
    background-color: var(--bs-body-bg);
}

.small-switch {
    min-width: 80px !important;
}

.member-information {
    min-height: 12rem;
}


.bg-primary-hover {
    &:hover {
        background-color: var(--bs-primary) !important;
    }
}


.text-primary-hover {
    &:hover {
        color: var(--bs-primary) !important;
    }
}

.text-secondary-hover {
    &:hover {
        color: var(--bs-secondary) !important;
    }
}

.text-title {
    font-size: var(--font-bold);
}

.text-medium {
    font-size: var(--font-medium);
}

.text-base {
    font-size: var(--font-base);
}

.btn-gray-0 {
    background-color: var(--bs-gray-0);
}

.border-gray-100 {
    border-color: var(--bs-gray-100) !important;
}

.text-gray-200 {
    color: var(--bs-gray-200) !important;
}

.bg-gray-0 {
    background-color: var(--bs-gray-0) !important;
}

.bg-gray-100 {
    background-color: var(--bs-gray-100) !important;
}


.bg-gray-200 {
    background-color: var(--bs-gray-200) !important;
}

.border-primary-hover {
    &:hover {
        border-color: var(--bs-primary) !important;
    }
}



.is-status {
    position: relative;

    &::before {
        display: block;
        content: "";
        height: 8px;
        width: 8px;
        background-color: var(--danger);
        border-radius: 100%;
        position: absolute;
        top: 45%;
        left: -8px;
        transform: translate(-50%);
    }
}

.pagination-style {
    li {
        a {
            @extend .mx-2;
            @extend .rounded-circle;
            @extend .border-0;

            .active,
            &:hover {
                @extend .bg-primary;
                @extend .text-secondary;
            }
        }
    }
}

.banner-profile {
    background-repeat: no-repeat;
    width: 100%;
    height: 224px;
    @extend .position-absolute;
    top: 0;
    z-index: 0;
}

.z-index-0 {
    z-index: 0 !important;
}

.w-260 {
    width: 260px;
}

.w-320 {
    width: 320px;
}

.w-308 {
    width: 308px;
}

.w-80 {
    width: 80%
}

.w-90 {
    width: 90%
}

.w-68 {
    width: 68px
}

.h-62 {
    height: 62px;
}

.h-68 {
    height: 68px;
}

.h-606 {
    height: 606px;
}
.h-353 {
    height: 353px;
}
.h-border-primary {
    &:hover {
        @extend .border-primary;
    }
}

.border-start-top {
    border-top-left-radius: 25px !important
}

.border-start-bottom {
    border-bottom-left-radius: 25px !important
}

.border-end-top {
    border-top-right-radius: 25px !important
}

.border-end-bottom {
    border-bottom-right-radius: 25px !important
}

.active-chat {
    border-left: 4px solid var(--bs-primary) !important;
    background-color: var(--bs-card-bg);
}

.inactive-chat {
    border-left: 4px solid var(--bs-card-bg) !important;
    background-color: var(--bs-card-bg);
}

.custom-padding-x {
    padding-left: calc(var(--bs-gutter-x) * .5);
    padding-right: calc(var(--bs-gutter-x) * .5);
}

.list-group-item.element-active {
    border-left: 2px solid var(--bs-primary);
}

.chat-online-status {
    position: absolute;
    top: 70%;
    left: 75%;
}

.badge-notification {
    position: absolute;
    top: 28%;
    left: 28%;
    background-color: var(--bs-primary) !important;
    padding: 3px;
    color: var(--bs-white) !important;
}

.pac-container {
    z-index: 11051 !important;
}

.rounded-15 {
    border-radius: 0.375rem !important;
}

.grid-lightbox {
    .yarl__container {
        background-color: transparent !important;
    }

    .yarl__slide_image {
        border-top-right-radius: $card-border-radius;
        border-top-left-radius: $card-border-radius;
    }
}

.list-lightbox {
    .yarl__container {
        background-color: transparent !important;
    }

    .yarl__slide_image {
        border-top-left-radius: $card-border-radius;
        border-bottom-left-radius: $card-border-radius;
    }
}

.img-mini {
    width:56px !important;
    height:60px !important;
}

.img-medium {
    width: 100px !important;
    height: 100px !important;
}

.msg-date {
    bottom: 0;
    right: -5px;
}

.react-input-emoji--container {
    margin: 0;
    border: 0;
}

.react-input-emoji--button svg {
    fill: var(--bs-body-color);
}

.react-tel-input .flag-dropdown:hover, .react-tel-input .flag-dropdown:focus {
    background-color: var(--bs-body-bg);
    border-color: var(--bs-border-color);
    box-shadow: none;
    border:none;
}

.switch.btn {
     min-width:8.5rem;
}

.thumbnail-wrapper {
    aspect-ratio: 15/16;
}

kbd {
    color: var(--bs-body-color);
    border: 1px solid var(--bs-body-color);
    background-color: transparent;
}


.date-divider {
   width: 100%;
   text-align: center;
   border-bottom: 1px solid var(--bs-primary);
   line-height: 0.1em;
   margin: 10px 0 20px;
}

.date-divider span {
    padding:0 10px;
}

// .table .dropdown-toggle {
//     color: var(--bs-table-color);
// }

.table-muted {
    -bs-table-color-type: var(--bs-table-striped-color);
    --bs-table-bg-type: var(--bs-table-striped-bg);
}