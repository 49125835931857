nav:first-child {
  .cart {
    display: flex;
    align-items: center;
  }
}

#logout-form {
  a {
    color: rgba(0, 0, 0, 0.5);
    text-decoration: none;
  }
}

.react-autosuggest__container input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
