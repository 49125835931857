body {
    .compare-bar {
        display: none;
        height: 120px;

        .wrapper {

            .item {
                padding: 0 !important;

                .remove {
                    right: 10px;
                    cursor: pointer;

                    @media (max-width: 767px) {
                        right: auto;
                        margin-left: 35px;
                    }
                }

                .product {
                    min-width: 100px;
                    min-height: 100px;
                    border: 3px solid var(--bs-white);
                    padding: 12px;

                    @media (max-width: 767px) {
                        min-width: 45px;
                        min-height: 50px;
                        max-width: 45px;
                        max-height: 50px;
                        border: 2px solid var(--bs-white);
                        padding: 6px;
                        margin-bottom: 0.25rem;
                    }

                    img {
                        height: 70px;

                        @media (max-width: 767px) {
                            height: 35px;
                        }
                    }
                }
            }
        }
    }

    &.has-compare {
        margin-top: 120px;

        .compare-bar {
            display: block;
        }
    }
}

.product-compare-modal-full-screen {
    .modal-dialog {
        width: 99% !important;
        max-width: 99% !important;
    }
}

.product-compare-table {
    @media (max-width: 1199.98px) {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}

.product-compare-btn {
    width: 120px;
}
