.react-autosuggest__suggestions-container--open {
  display: block;
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 999;
}

ul.react-autosuggest__suggestions-list {
  padding: 0;
  background-color: var(--bs-white);
  border: 1px solid var(--bs-dark);

  li {
    list-style: none;
    padding: 0.5rem;
    font-size: 16px;
  }

  li em {
      background-color: yellow;
      font-style: inherit;
      font-size: inherit;
  }
}
