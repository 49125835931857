
.forgotten-password-link {
  font-size: 13px;
}

.socialButtons h6,
#login-form label[for="formLoginEmail"],
#login-form label[for="formLoginPassword"] {
  word-wrap: normal;
}

#login-form button {
    @media (max-width: 991px) {
        width: 100%;
    }
}

.remember-signin-user {
    align-items: center;
    justify-content: space-between;
}

.facebook-login, .twitter-login {
    width: 100%;
}

.linkedin {
    background-color: #0073b1;
}
