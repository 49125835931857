li.rct-node {
    line-height: 3rem;
}

.rct-node-icon {
    color:var(--bs-primary);
}

.react-checkbox-tree {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5;
    font-family: 'Poppins', 'Roboto';
}
