footer {
    .card .card-footer {
        line-height: 75px;

        .btn.btn-primary {
            display: inline-block;
            vertical-align: middle;
        }
    }

    .list-group-horizontal {
        display: flex;
        align-items: center;

        .connect-text {
            margin: 0;
        }

        .social-icon {
            @media (max-width: 400px) {
                font-size: 10px;
            }
        }
    }

    .banner {
        @media(min-width: 768px) {
            border-right: 1px solid #dee2e6;
        }
    }

    .footer-menu {
        h6 {
            width: 100%;
        }
    }
}
