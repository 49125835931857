.pageLoadingSpinner {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -80px;
    margin-left: -80px;
    width: 180px;
    height: 180px;
}

#path_s {
    stroke-dasharray: 131;
    stroke-dashoffset: 500;
    stroke-width: 1;
    stroke: var(--bs-body-color);
    animation: sign 5s;
    animation-fill-mode: forwards;
}

#path_top {
    stroke-dasharray: 116;
    stroke-dashoffset: 500;
    stroke-width: 1;
    stroke: var(--bs-body-color);
    animation: sign 5s;
    animation-fill-mode: forwards;
}

#path_c {
    stroke-dasharray: 93;
    stroke-dashoffset: 500;
    stroke-width: 1;
    stroke: var(--bs-body-color);
    animation: sign 5s;
    animation-fill-mode: forwards;
}

@keyframes sign {
    to {
        stroke-dashoffset: 0;
    }
}
