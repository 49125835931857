#register-form {
  label {
    word-wrap: normal;
  }

  .border-dashed {
    border-right-style:none !important;
    border-left-style:dashed !important;
    border-top-style:dashed !important;
    border-bottom-style:dashed !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-color: var(--bs-black) !important;
  }

  #inputGroupPrependQuestion {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  button {
    @media(max-width: 575px) {
        width: 100%;
    }
  }
}
