/* Container needed to position the overlay. Adjust the width as needed */
.holder {
  position: relative;
  width: 100%;
  max-width: 400px;
}

/* Make the image to responsive */
.holder > img {
  width: 100%;
  height: auto;
}

/* The overlay effect (full height and width) - lays on top of the container and over the image */
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .3s ease;
}

.holder.addedToCompare, .holder:hover > img {
  opacity: 0.8;
}

/* When you mouse over the container, fade in the overlay icon*/
.holder.addedToCompare .overlay, .holder:hover .overlay {
  opacity: 1;
}

/* The icon inside the overlay is positioned in the middle vertically and horizontally */
.icon {
  cursor: pointer;
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}