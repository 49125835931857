#account-order-history {
  .order-history-wrapper {
    @media (max-width: 767px) {
      overflow-y: scroll;
    }
  }

  table {
    @media (max-width: 450px) {
      font-size: 14px;
    }
  }

  .card-body {
    @media (max-width: 450px) {
      overflow-y: scroll;
    }
  }
}

tr.disabled > td {
    background-image: linear-gradient(45deg, transparent 25%, var(--bs-light) 25%, var(--bs-light) 50%, transparent 50%, transparent 75%, var(--bs-light) 75%, var(--bs-light) 100%);
    background-size: 56.57px 56.57px;
}
